import React, { Component } from "react"

import Layout from "../../components/layout"
import GatedContent from "../../components/gatedcontent"

import {CopyTextbox, CopySpan} from '../../components/copybox/'
import "../../styles/copybox.css"

import imgsublimeputty from "../../images/guide/sublimeputty.png"


export default class SshSublimeIndex extends Component {
	render() {
		return (
			<Layout showtitle={true} showtitleback={true} activePath='guide' fullPath='guide/ssh-sublime' pageTitle="SSH Using Sublime" privatePage={true}>
				<GatedContent>
					<div className={"margin-content"}>
						Edit remote ssh files via Sublime Text Editor.
						<h2>Usage</h2>
						<ol>
							<li>Open Sublime Text Editor in your local machine</li>
							<li>Run the following command from your ssh terminal:</li>
							<div className={"copybox-textbox"}>
								rsub &lt;file&gt;
							</div>
							<ul>
								Example:<br/>
								rsub myfile.txt
							</ul>
							The file should appear as a tab in your sublime editor, and you can edit if you're working on a local file.
						</ol>
						<ul>
							* Repeat Step 2 for every file you want to edit.  There can be multiple files openned.
						</ul>
						<strong>NOTE: You'll need to close the file at Sublime before you can exit the SSH session</strong>

						<h2>Configuration</h2>
						One-time configurations necessary to start using sublime to edit files on an ssh server.
						<h3>SSH client</h3>
						Setup a reverse tunnel at port 52698
						<ul>
							<li>Windows (Putty)</li>
							<ol>
								<li>Open Putty</li>
								<li>Load the Session you want to configure</li>
								<li>From the Category menu on the left-hand side. Look for "Connection&nbsp;&gt;&nbsp;SSH&nbsp;&gt;&nbsp;Tunnels"</li>
								<li>Add a new forwarded port</li>
								<ul>
									<li><strong>Source Port</strong>: <CopySpan copytext="52698" /></li>
									<li><strong>Destination</strong>: <CopySpan copytext="localhost:52698" /></li>
									<li>Select <span className={"copybox-span"}>Remote</span></li>
									<li>Select <span className={"copybox-span"}>Auto</span></li>
									<img alt="" src={imgsublimeputty} />
								</ul>
								<li>Click "Session" from the Category list on the left-hand side</li>
								<li>Save Session</li>
							</ol>
							<li>Mac/Linux</li>
							<ul>
								<li>Add <CopySpan copytext="-R 52698:localhost:52698" /> when connecting via ssh to server</li>
								<li>You can configure this in your ssh config file</li>
							</ul>
						</ul>
						<h3>Sublime</h3>
						Install Sublime's <i>rsub</i> package.
						<ol>
							<li>Install appropriate <a target="_blank" className={"visiblelink"} rel="noopener noreferrer" href="https://www.sublimetext.com/3">Sublime Text Editor</a> version</li>
							<li>Install Package Control (usually under "Tools" if it' not yet installed)</li>
							<li>Go to "Package Control".  (Ctrl-Shift-P on Linux/Win, Cmd-Shift-P on Mac)</li>
							<li>Search for "Install Package", and click</li>
							<li>Look for <i>rsub</i>, then click to install</li>
						</ol>
						<h3>Server</h3>
						Install rmate as rsub.
						<ol>
							<li>Install rmate</li>
							<CopyTextbox copytext="sudo wget -O /usr/local/bin/rsub https://raw.github.com/aurora/rmate/master/rmate" />
							<li>Map rmate as rsub</li>
							<CopyTextbox copytext="sudo chmod a+x /usr/local/bin/rsub" />
						</ol>
						
					</div>
				</GatedContent>
			</Layout>
		)
	}
}



